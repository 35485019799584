// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

import { _getModalContent } from "./functions";

// Style
import CommonStyle from "../style.module.scss";
import Style from "./style.module.scss";

// Fields
import { ExpansionPanel, ResponsiveModal } from "fields";

// Lib
import { Math } from "lib/functions";
import { InfoIcon } from "assets/Icons";

class FeeSummary extends React.Component {
  state = { show_fee_info_modal: false };

  onShowFeeInfoModal = ({ fee_id, fee_name = "" }) =>
    this.setState({
      show_fee_info_modal: true,
      fee_id,
      fee_name,
    });

  onCloseFeeInfoModal = () => this.setState({ show_fee_info_modal: false });

  renderFeeDetails = (fees_details = [], guest_fees = {}) =>
    fees_details
      .filter((fee_detail) => fee_detail.new_fee !== 0)
      .map((fee_detail) => {
        const { id: fee_id } = fee_detail;
        const { name: fee_name } = guest_fees[fee_id] || "";
        return (
          <div key={fee_id} className={Style.order_details}>
            <div className={Style.label}>
              <div>{fee_name} </div>
              <div
                className={Style.infoIcon}
                onClick={() =>
                  this.onShowFeeInfoModal({
                    fee_id,
                    fee_name,
                  })
                }
              >
                <InfoIcon />
              </div>
            </div>
            <p>${Math._roundNumber(fee_detail.new_fee)}</p>
          </div>
        );
      });

  renderFeeInfoModal = () => {
    const { fee_id, fee_name = "" } = this.state;
    return (
      <ResponsiveModal
        contentLabel="Order Details"
        headerProps={{
          header: fee_name,
          show_back_button: true,
          show_close_button: false,
        }}
        classNames={{ modal: Style.modal }}
        onCloseModal={this.onCloseFeeInfoModal}
      >
        <p className={Style.modal_content}>{_getModalContent({ fee_id })}</p>
      </ResponsiveModal>
    );
  };

  render() {
    const {
      salesTax = 0,
      subTotal = 0,
      is_storefront_enabled = false,
      fees_details = [],
      guest_fees = {},
      tipAmount = 0,
      total_delivery_fee = 0,
    } = this.props;
    const { show_fee_info_modal } = this.state;
    const tax_amount = Math._roundNumber(subTotal * parseFloat(salesTax));

    return (
      <ExpansionPanel
        moduleTitle="Fee Summary"
        showExpandableContent
        titleClassName={CommonStyle.expansionPanelTitle}
      >
        <div className={Style.fee_summary_container}>
          <div className={Style.order_details}>
            <div className={Style.label}>
              <div>Sub Total</div>
              <div
                className={Style.infoIcon}
                onClick={() =>
                  this.onShowFeeInfoModal({
                    fee_id: "sub_total",
                    fee_name: "Sub Total",
                  })
                }
              >
                <InfoIcon />
              </div>
            </div>
            <p>${Math._roundNumber(parseFloat(subTotal))}</p>
          </div>
          <div className={Style.order_details}>
            <p>Estimated Tax</p>
            <p>${tax_amount}</p>
          </div>
          {tipAmount > 0 && (
            <div className={Style.order_details}>
              <div className={Style.label}>
                <div>Tip (Optional)</div>
              </div>
              <p>${Math._roundNumber(tipAmount)}</p>
            </div>
          )}
          {total_delivery_fee > 0 && total_delivery_fee && (
            <div className={Style.order_details}>
              <div className={Style.label}>
                <div>Delivery Fee</div>
                <div
                  className={Style.infoIcon}
                  onClick={() =>
                    this.onShowFeeInfoModal({
                      fee_id: "delivery",
                      fee_name: "Delivery",
                    })
                  }
                >
                  <InfoIcon />
                </div>
              </div>
              <p>${Math._roundNumber(total_delivery_fee)}</p>
            </div>
          )}
          {is_storefront_enabled &&
            this.renderFeeDetails(fees_details, guest_fees)}
          {show_fee_info_modal && this.renderFeeInfoModal()}
        </div>
      </ExpansionPanel>
    );
  }
}

FeeSummary.propTypes = {
  is_storefront_enabled: PropTypes.bool.isRequired,
  subTotal: PropTypes.number.isRequired,
  salesTax: PropTypes.number.isRequired,
  tipAmount: PropTypes.number.isRequired,
  guest_fees: PropTypes.object.isRequired,
  fees_details: PropTypes.array.isRequired,
};

FeeSummary.defaultProps = {
  is_storefront_enabled: false,
  subTotal: 0,
  salesTax: 0,
  fees_details: [],
  guest_fees: [],
  tipAmount: 0,
};

export default FeeSummary;
