// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { set } from "object-path-immutable";
import LazyLoad from "react-lazyload";
import { CustomerInterfaceConsumer, withContext } from "context";

import { _sortMenuItems, filteredCategories } from "../HelperFunctions";

// Utils
import { _scrollTo } from "utils";

// Style
import Style from "./style.module.scss";

// Components
import { CustomerMenuElements, Modals } from "components";

// Fields
import { Button, PageMsg } from "fields";

// Icons
import { ListIcon } from "assets/Icons";

// Lib
import { FoodMenuFuncs, DateTime } from "lib/functions";

class MenuMobileSite extends React.Component {
  refs = {};
  state = { filters: {} };

  componentDidMount() {
    window.addEventListener("beforeunload", this._confirm);
    window.history.pushState(null, "", window.location.href);
    this.onGetTodayHours();
    window.onpopstate = this._backConfirm;
  }
  onGetTodayHours = async () => {
    const { GetCurrentTime } = DateTime;
    const { timeZone = "America/New_York" } = this.props.context.shopBasicInfo;
    const { name_of_the_day: today, time } = await GetCurrentTime({ timeZone });
    this.setState({ today, time });
  };
  componentWillUnmount() {
    window.removeEventListener("beforeunload", this._confirm);
    window.onpopstate = () => {};
  }

  _backConfirm = () => this.setState({ selectedGroupID: "" });

  _confirm = (e) => {
    var confirmationMessage = "o/";
    e.returnValue = confirmationMessage;
    return confirmationMessage;
  };

  onScrollToCategory = ({ categoryId = "" }) => {
    const categoryRef = this.refs[categoryId];
    if (categoryId && categoryRef)
      _scrollTo({ ref: categoryRef, top: "-120px" });
  };

  renderAllCategories = () => {
    const { _sortMenuCategories } = FoodMenuFuncs;
    const { menuInfo = {} } = this.props;
    const { groups: categories } = menuInfo;
    const { today = "", time = "" } = this.state;
    const filter_category = filteredCategories({ categories, today, time });

    if (Object.keys(filter_category).length === 0)
      return <PageMsg>Menu is empty</PageMsg>;

    return _sortMenuCategories(filter_category).map((categoryId) => {
     const {
        groupIsArchived = false,
        groupName,
        items = {},
        modifierGroups,
        is_available = false,
        time_setting = {},
      } = filter_category[categoryId];
      if (Object.keys(items).length === 0 || groupIsArchived) return null;
      return (
        <React.Fragment key={categoryId}>
          <LazyLoad offset={40}>
            <div
              style={{ marginBottom: "10px" }}
              ref={(ref) => (this.refs = set(this.refs, categoryId, ref))}
            >
              <div className={Style.categoryNameContainer}>
                <h3 className={Style.categoryName}>{groupName}</h3>
              </div>
              <CustomerMenuElements.ListOfItems
                categoryModifierGroups={modifierGroups}
                is_available={is_available}
                items={items}
                sortedItemIDs={_sortMenuItems({ items })}
                time_setting={time_setting}
              />
            </div>
          </LazyLoad>
        </React.Fragment>
      );
    });
  };

  renderCategoryNavigationModal = () => {
    const { menuInfo = {} } = this.props;
    const { today = "", time = "" } = this.state;
    const { groups: categories } = menuInfo;

    return (
      <Modals.CategoryNavigationModal
        categories={filteredCategories({ categories, today, time })}
        onCloseModal={() =>
          this.setState({ showCategoryNavigationModal: false })
        }
        onScrollToCategory={this.onScrollToCategory}
      />
    );
  };


  renderMenuNameContainer = () => {
    const { menuInfo = {} } = this.props;
    const { menuName } = menuInfo;
    return (
      <div
        className={Style.menuNameContainer}
        onClick={() => this.setState({ showCategoryNavigationModal: true })}
      >
        <div className={Style.menuNameContent}>
          <h2 className={Style.menuName}>{menuName}</h2>
          <Button className={Style.selectCategoryButton} name="select category">
            <ListIcon /> Categories
          </Button>
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        {this.state.showCategoryNavigationModal &&
          this.renderCategoryNavigationModal()}
        <div>
          {this.renderMenuNameContainer()}
          <div>{this.renderAllCategories()}</div>
        </div>
      </React.Fragment>
    );
  }
}

MenuMobileSite.propTypes = {
  is_available: PropTypes.bool,
  menuInfo: PropTypes.shape({
    groups: PropTypes.object,
    menuName: PropTypes.string.isRequired,
  }).isRequired,
  time_setting: PropTypes.object,
};

export default withContext(CustomerInterfaceConsumer)(MenuMobileSite);
