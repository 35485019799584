// BUTI DINERS, INC. All right Reserved ©

import React from "react";

import NoServiceMessage from "./NoServiceMessage";

// Style
import Style from "./style.module.scss";
import UnavailableItemMessages from "./UnavailableItemMessages";

const _initializeDetailsOfItemInCart = ({ itemID = "", itemInfo = {} }) => {
  const {
    itemIsOnSale = {},
    itemKitchenChitName = "",
    itemName,
    itemPrice = "",
    itemSaleRate = 0,
  } = itemInfo;
  const detailsOfItemInCart = {
    itemID,
    itemSimpleDescription: { itemKitchenChitName, itemName, itemPrice },
    quantity: 1,
  };
  const saleRate = isNaN(parseFloat(itemSaleRate))
    ? 0
    : parseFloat(itemSaleRate);
  const isItemOnSale = Boolean(itemIsOnSale.true) && saleRate > 0;
  return !isItemOnSale
    ? detailsOfItemInCart
    : {
        ...detailsOfItemInCart,
        itemIsOnSale: isItemOnSale,
        itemSaleRate: saleRate,
      };
};

const _renderButtons = ({
  quantityCalculator,
  shop_membership_status,
  submitButton,
  todayHours = {},
  is_available,
  time_setting = {},
}) => {
  const { canShopAcceptOrders = true, isShopClosed = false } = todayHours;
  if (!canShopAcceptOrders || isShopClosed)
    return (
      <NoServiceMessage
        shop_membership_status={shop_membership_status}
        todayHours={todayHours}
      />
    );
  if (!is_available)
    return (
      <UnavailableItemMessages
        time_setting={time_setting}
        today_hours={todayHours}
      />
    );
  return (
    <div className={Style.sbqcContainer}>
      {quantityCalculator}
      {submitButton}
    </div>
  );
};

export { _initializeDetailsOfItemInCart, _renderButtons };
