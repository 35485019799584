// BUTI DINERS, INC. All right Reserved ©

import React, { Component } from "react";
import PropTypes from "prop-types";

// Style
import CommonStyle from "../style.module.scss";

// Components
import { CustomerMenuElements } from "components";

// Fields
import { PageMsg } from "fields";

class ListOfItems extends Component {
  renderItems = (items = {}, is_available = false, time_setting = {}) =>
    (this.props.sortedItemIDs || Object.keys(items)).map((itemID) => {
      const { itemIsArchived } = items[itemID];
      return (
        !itemIsArchived && (
          <div key={itemID}>
            <CustomerMenuElements.MenuItem
              categoryModifierGroups={this.props.categoryModifierGroups}
              itemID={itemID}
              itemInfo={items[itemID]}
              is_available={is_available}
              time_setting={time_setting}
            />
          </div>
        )
      );
    });

  render() {
    const { items, is_available = false, time_setting = {} } = this.props;
    if (Object.keys(items).length === 0)
      return <PageMsg>No items found.</PageMsg>;
    return (
      <div className={CommonStyle.desktopList}>
        {this.renderItems(items, is_available, time_setting)}
      </div>
    );
  }
}

ListOfItems.propTypes = {
  categoryModifierGroups: PropTypes.object,
  items: PropTypes.object,
  is_available: PropTypes.bool,
  listContainerClassname: PropTypes.string,
  sortedItemIDs: PropTypes.array,
  showAllItems: PropTypes.bool,
  time_setting: PropTypes.object,
};

ListOfItems.defaultProps = {
  categoryModifierGroups: {},
  items: {},
  is_available: false,
  sortedItemIDs: null,
  showAllItems: false,
  time_setting: {},
};

export default ListOfItems;
