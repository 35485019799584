// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

import DesktopMenuGroup from "./DesktopMenuGroup";

// Style
import Style from "./style.module.scss";

// Context
import { CustomerInterfaceConsumer, withContext } from "context";

// Fields
import { PageMsg } from "fields";

// Lib
import { DateTime, FoodMenuFuncs } from "lib/functions";
import { filteredCategories } from "../HelperFunctions";

class MenuDesktopSite extends React.Component {
  state = {};
  componentDidMount = () => {
    this.onGetTodayHours();
  };

  onGetTodayHours = async () => {
    const { GetCurrentTime } = DateTime;
    const { timeZone = "America/New_York" } = this.props.context.shopBasicInfo;
    const { name_of_the_day: today, time } = await GetCurrentTime({ timeZone });
    this.setState({ today, time });
  };

  renderCategories = (categories = {}) => {
    const { _sortMenuCategories } = FoodMenuFuncs;
    const { context } = this.props;
    if (Object.keys(categories).length === 0)
      return <PageMsg>Menu is empty</PageMsg>;
    return _sortMenuCategories(categories).map((categoryId, categoryIndex) => {
      const {
        groupIsArchived = false,
        groupName,
        items = {},
        modifierGroups,
        is_available = false,
        time_setting = {},
      } = categories[categoryId];
      if (Object.keys(items).length === 0 || groupIsArchived) return null;
      return (
        <div
          className={Style.categoryContainer}
          key={categoryId}
          ref={(ref) => context.onSetCategoryRef(categoryId, ref)}
        >
          <DesktopMenuGroup
            groupName={groupName}
            items={items}
            modifierGroups={modifierGroups}
            onSetGroupVisibility={(inViewport) =>
              context.onSetCategoryVisibility(categoryIndex, inViewport)
            }
            is_available={is_available}
            time_setting={time_setting}
          />
        </div>
      );
    });
  };

  render() {
    const { menuInfo = {} } = this.props;
    const { today = "", time = "" } = this.state;
    const { groups: categories = {} } = menuInfo;
    return (
      <div className={Style.menuContainer}>
        {this.renderCategories(filteredCategories({ categories, today, time }))}
      </div>
    );
  }
}

MenuDesktopSite.propTypes = {
  context: PropTypes.shape({
    onSetCategoryRef: PropTypes.func.isRequired,
    onSetCategoryVisibility: PropTypes.func.isRequired,
    shopBasicInfo: PropTypes.object,
  }).isRequired,
  is_available: PropTypes.bool,
  menuInfo: PropTypes.shape({
    groups: PropTypes.object,
    menuName: PropTypes.string.isRequired,
  }).isRequired,
  time_setting: PropTypes.object,
};

export default withContext(CustomerInterfaceConsumer)(MenuDesktopSite);
