// BUTI DINERS, INC. All right Reserved ©
import React from "react";
import PropTypes from "prop-types";

import cx from "classnames";

// Style
import Style from "./style.module.scss";

// Context
import { CustomerInterfaceConsumer, withContext } from "context";

// Fields
import { ResponsiveModal } from "fields";

// Assets
import { ClockIcon, CloseSignIcon } from "assets/Icons";
import { formatTime, sortWeekDays } from "./helperFunctions";

class UnavailableItemMessages extends React.Component {
  state = { show_time_available: false };

  renderContent = () => {
    return (
      <div className={Style.notAcceptingOrder}>
        <div className={Style.icon}>
          <CloseSignIcon />
        </div>
        <div className={Style.text}>
          <h3>Currently unavailable</h3>
          {this.renderTimeAvailableButton()}
        </div>
      </div>
    );
  };

  renderTimeAvailableButton = () => (
    <div
      className={Style.seeTimeAvailable}
      onClick={() => this.setState({ show_time_available: true })}
    >
      See available hours
    </div>
  );

  renderDay = (
    day = "",
    startAt = "",
    endAt = "",
    is_closed_all_day = false
  ) => {
    const { today_hours: { today = "" } = {} } = this.props;
    if (!startAt && !endAt && !is_closed_all_day) return null;

    return (
      <div
        key={day}
        className={cx(
          Style.special_hour_container,
          today.toLowerCase() === day.toLowerCase() && Style.isToday
        )}
      >
        <div className={Style.special_hour_date}>{day}</div>
        {is_closed_all_day ? (
          <div className={Style.closed_all_day}>NOT AVAILABLE</div>
        ) : (
          <div className={Style.time_pickers_group}>
            <div>{formatTime(startAt)}</div>
            <div style={{ margin: "0 4px" }}>-</div>
            <div>{formatTime(endAt)}</div>
          </div>
        )}
      </div>
    );
  };

  renderDateOptions = ({
    selected_date_options = {},
    end_time = "",
    start_time = "",
  }) => {
    if (Array.isArray(selected_date_options)) {
      const sorted_days = sortWeekDays(selected_date_options);
      return sorted_days.map((day) =>
        this.renderDay(day, start_time, end_time)
      );
    }

    if (typeof selected_date_options === "object") {
      const sorted_days = sortWeekDays(Object.keys(selected_date_options));
      return sorted_days.map((day) => {
        const { startAt = "", endAt = "", is_closed_all_day = false } =
          selected_date_options[day] || {};
        return this.renderDay(day, startAt, endAt, is_closed_all_day);
      });
    }

    return null;
  };

  renderTimeAvailable = (time_setting = {}) => {
    const {
      end_time = "",
      start_time = "",
      selected_date_options = {},
    } = time_setting;

    return (
      <ResponsiveModal
        classNames={{ modal: Style.modal }}
        contentLabel="Item Availability Time"
        headerProps={{ show_back_button: true, show_close_button: false }}
        onCloseModal={() => this.setState({ show_time_available: false })}
      >
        <div>
          <div className={Style.infoContainer}>
            <ClockIcon className={Style.icon} />
            Item Availability Time
          </div>
          <div
            className={Style.hoursContainer}
            style={{ marginBottom: "1.2rem" }}
          >
            {this.renderDateOptions({
              selected_date_options,
              end_time,
              start_time,
            })}
          </div>
        </div>
      </ResponsiveModal>
    );
  };

  render() {
    const { time_setting = {} } = this.props;
    const { show_time_available = false } = this.state;
    return (
      <React.Fragment>
        {this.renderContent()}
        {show_time_available && this.renderTimeAvailable(time_setting)}
      </React.Fragment>
    );
  }
}

UnavailableItemMessages.propTypes = {
  time_setting: PropTypes.shape({
    end_time: PropTypes.string,
    start_time: PropTypes.string,
    selected_date_options: PropTypes.object,
  }),
  today_hours: PropTypes.shape({
    today: PropTypes.string,
  }),
};

export default withContext(CustomerInterfaceConsumer)(UnavailableItemMessages);
