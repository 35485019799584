// BUTI DINERS, INC. All right Reserved ©

import React, { Component } from "react";
import PropTypes from "prop-types";
import _get from "lodash.get";
import { set } from "object-path-immutable";

import {
  _calculateMerchantFees,
  _findCustomerDefaultPhoneNumber,
  _generateInitialState,
  _getTotalDeliveryFee,
  CreateOutOfStockItemsWarning,
  CreateOrderDetails,
  GetUpdatedCustomerProfile,
  RemoveOutOfStockItems,
  _calculateFees,
} from "./helperFunctions";

// Utils
import { _scrollTo } from "utils";

// Style
import Style from "./style.module.scss";

// Components
import { ConfirmOrderForm, ConfirmOrderModules, Modals } from "components";

// Context
import { CustomerInterfaceConsumer, withContext } from "context";

// Lib
import {
  CartFuncs,
  LocalStorage,
  OrdersManagement,
  ShowConfirmNotif,
} from "lib/functions";
import { Customers, Merchants } from "lib/services";

const { _checkIfStoreAcceptOrders } = OrdersManagement;
const { GetCustomerPaymentMethods } = Customers.GetRequests;
const { ChangeCustomerProfile, ChangeCustomerCart } = Customers.PostRequests;
const { CheckIfItemsAreInStock, GetShopStoreFront } = Merchants.GetRequests;
const { CreatePayment } = Merchants.PostRequests;

class ConfirmOrder extends Component {
  refs = {};
  confirmNotif = null;

  constructor(props) {
    super(props);
    const { showCateringMenu } = props.context;
    this.state = _generateInitialState({
      shopID: props.context.shopID,
      showCateringMenu,
    });
  }

  componentDidMount = async () => {
    const { customerProfile = {} } = this.props.context;
    const { name = "", phoneList = {}, stripeCustomerID } = customerProfile;
    this.setState(
      {
        customerName: name,
        phoneNumber: _findCustomerDefaultPhoneNumber({ phoneList }),
      },
      () =>
        stripeCustomerID && this.onGetCustomerPaymentMethods(stripeCustomerID)
    );
    window.addEventListener("beforeunload", this.onHandleLeavePage);
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = this._backConfirm;
    const { store_front = {} } = await GetShopStoreFront({
      shopID: this.props.context.shopID,
    });
    this.setState({
      store_front,
    });
  };

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onHandleLeavePage);
    window.onpopstate = () => {};
  }

  _backConfirm = () => this.props.onCloseModal();

  onHandleLeavePage = (e) => {
    const confirmationMessage = "Some message";
    e.returnValue = confirmationMessage;
    return confirmationMessage;
  };

  onCalculateApplicationFee = ({
    merchantFeesMonetaryAmount,
    paymentMethod,
    skipliShareOfTips,
    shop_id,
    totalAmount,
  }) => {
    const { courierQuotes, selectedCourier, tipAmount } = this.state;

    const { context } = this.props;
    const { order_fulfillment_method = "pickUp" } = context;

    return CartFuncs.CalculateApplicationFeeAmount({
      cardBrand: _get(paymentMethod, "card.brand"),
      merchantFeesMonetaryAmount,
      orderDeliveryTypeID: order_fulfillment_method,
      skipliShareOfTips,
      shop_id,
      stripeTotalAmount: totalAmount * 100,
      subTotal: parseFloat(this.props.subTotal),
      tipAmount,
      totalDeliveryFee:
        order_fulfillment_method === "deliver"
          ? _getTotalDeliveryFee({ courierQuotes, selectedCourier })
          : 0,
    });
  };

  onChangeDeliveryAddress = (fieldID, fieldValue) => {
    this.setState({
      deliveryAddress: set(this.state.deliveryAddress, fieldID, fieldValue),
    });
  };

  onChangeCourierQuotes = ({ courier = "postmates", quote = {} }) =>
    this.setState({
      courierQuotes: set(this.state.courierQuotes, courier, quote),
    });

  onChangeDeliveryType = (orderDeliveryTypeID) => {
    const { context = {} } = this.props;
    const { onChangeOrderFulfillmentMethod = () => {} } = context;
    const ref = this.refs["expressCheckout"];
    if (ref) _scrollTo({ ref });
    onChangeOrderFulfillmentMethod(orderDeliveryTypeID);
    if (orderDeliveryTypeID !== "deliver")
      this.setState({ courierQuotes: {}, deliveryAddress: {} });

    // if (types.includes(orderDeliveryTypeID)) {
    //   this.setState({ orderDeliveryTypeID }, () => {
    //     if (orderDeliveryTypeID !== "deliver")
    //       this.setState({ courierQuotes: {}, deliveryAddress: {} });
    //   });
    // }
  };

  onCloseAddPaymentMethodModal = () => {
    const stripeCustomerID = _get(
      this.props.context,
      "customerProfile.stripeCustomerID"
    );
    this.setState({ showAddPaymentMethodModal: false }, () =>
      this.onGetCustomerPaymentMethods(stripeCustomerID)
    );
  };

  onCreateCharge = async ({ event, totalAmount }) => {
    const { context, salesTax = 0 } = this.props;
    const {
      cart,
      customerProfile = {},
      shopBasicInfo,
      shopID,
      todayHours,
    } = context;
    const { order_fulfillment_method = "pickUp" } = context;

    const {
      selectedPaymentWalletOption,
      store_front = {},
      tipAmount = 0,
      courierQuotes = {},
      selectedCourier,
    } = this.state;
    // Check if the store accepts the order
    const { timeZone } = shopBasicInfo;
    const {
      is_enable: is_storefront_enabled = false,
      guest_fees = {},
    } = store_front;
    const {
      currentTimestamp,
      shouldAcceptOrder = false,
    } = await _checkIfStoreAcceptOrders({ timeZone, todayHours });
    if (!shouldAcceptOrder) {
      ShowConfirmNotif({
        message: "Shop is currently closed. Please try again later.",
        type: "warning",
      });
      return;
    }
    // Check if there is any items that are out of stock
    const { outOfStockItemIDs = [] } = await CheckIfItemsAreInStock({
      itemIDs: Object.values(cart).map(({ itemID }) => itemID),
      shopID,
    });
    if (outOfStockItemIDs.length > 0) {
      this.onRemoveOutOfStockItems({ cart, outOfStockItemIDs });
      if (selectedPaymentWalletOption === "other") {
        event && event.complete("fail");
      }
      return;
    }
    // If all items are in stock, proceed to create the charge
    this.setState({ isCreatingCharge: true });
    let paymentMethodID;
    let paymentMethod;
    if (selectedPaymentWalletOption === "card") {
      const { paymentMethods, selectedPaymentMethodFingerprint } = this.state;
      paymentMethod = paymentMethods[selectedPaymentMethodFingerprint];
      paymentMethodID = paymentMethod.id;
    } else {
      paymentMethod = event.paymentMethod;
      paymentMethodID = paymentMethod.id;
    }

    const { stripeCustomerID = "" } = customerProfile;
    const { merchantFees = {}, skipliShareOfTips = {} } = shopBasicInfo;
    const { fees_list = {}, total_fee = 0 } = _calculateFees({  // total_fee is store_front_total_fee
      fees: guest_fees,
      subTotal: parseFloat(this.props.subTotal),
      salesTax,
      tipAmount,
      totalDeliveryFee:
        order_fulfillment_method === "deliver"
          ? _getTotalDeliveryFee({ courierQuotes, selectedCourier })
          : 0,
    });
    const { feesList, totalFee = 0 } = _calculateMerchantFees({
      merchantFees,
      subTotal: parseFloat(this.props.subTotal),
    });

    try {
      const { pendingPaymentIntent } = await CreatePayment({
        amount: parseInt(totalAmount * 100),
        applicationFeeAmount: this.onCalculateApplicationFee({
          merchantFeesMonetaryAmount: totalFee,
          paymentMethod,
          skipliShareOfTips,
          shop_id: shopID,
          totalAmount,
        }),
        paymentMethodID,
        selectedPaymentWalletOption,
        stripeAccountID: _get(
          shopBasicInfo,
          "stripeConnectInfo.stripeAccountID"
        ),
        stripeCustomerID,
      });
      const { client_secret } = pendingPaymentIntent;
      const {
        paymentIntent,
        error,
      } = await this.props.stripe
        .handleCardPayment(client_secret, { payment_method: paymentMethodID })
        .finally(() => this.setState({ isCreatingCharge: false }));

      if (error) {
        if (selectedPaymentWalletOption === "other") {
          event && event.complete("fail");
        }
        this.confirmNotif = ShowConfirmNotif({
          message: "Please try another card or add a new card",
          options: { autoClose: 3000 },
          type: "error",
        });
        return;
      }
      if (selectedPaymentWalletOption === "other") {
        event && event.complete("success");
      }

      const { context } = this.props;
      const { order_fulfillment_method = "pickUp" } = context;

      this.props.onSubmitOrder(
        CreateOrderDetails({
          ...this.state,
          orderDeliveryTypeID: order_fulfillment_method,
          merchantFees: { feesList, totalFee },
          paymentIntentID: paymentIntent.id,
          skipliShareOfTips,
          totalAmount,
          timeStamp: currentTimestamp,
          shop_id: shopID,
          subTotal: parseFloat(this.props.subTotal),
          store_front: is_storefront_enabled ? { fees_list, total_fee } : {},  // total_fee is store_front_total_fee
          total_fee,  // total_fee is store_front_total_fee
        })
      );
      this.props.onCloseModal();
    } catch {
    } finally {
      this.setState({ isCreatingCharge: false });
    }
  };

  onGetCustomerPaymentMethods = async (stripeCustomerID) => {
    this.setState({ isLoadingPaymentMethods: true });
    try {
      const { paymentMethods = {} } = await GetCustomerPaymentMethods({
        stripeCustomerID,
      });
      this.setState({ paymentMethods }, () => {
        const pm_fingerprints = Object.keys(paymentMethods) || [];
        if (pm_fingerprints.length > 0)
          this.onSelectPaymentMethod(pm_fingerprints[0]);
      });
    } catch {
      this.setState({ paymentMethods: {} });
    } finally {
      this.setState({ isLoadingPaymentMethods: false });
    }
  };

  onRemoveOutOfStockItems = async ({ cart, outOfStockItemIDs }) => {
    const updatedCart = RemoveOutOfStockItems({ cart, outOfStockItemIDs });
    const { showCateringMenu = false } = this.props.context;
    const { success } = await ChangeCustomerCart({
      applyToCateringCart: showCateringMenu,
      cart: updatedCart,
      shopID: this.props.context.shopID,
      uuid: LocalStorage.getItemFromStorage("uuid"),
    });
    this.confirmNotif = ShowConfirmNotif({
      message: success
        ? CreateOutOfStockItemsWarning({ cart, outOfStockItemIDs })
        : "Some items are out of stock but could not be removed. Please refresh the page.",
      options: { autoClose: 10000 },
      type: success ? "warning" : "error",
    });
    if (success) {
      const { onChangeCustomerCart } = this.props.context;
      onChangeCustomerCart(updatedCart);
    }
  };

  onSelectPaymentMethod = (fingerprint) => {
    const { selectedPaymentMethodFingerprint } = this.state;
    this.setState({
      selectedPaymentMethodFingerprint:
        fingerprint === selectedPaymentMethodFingerprint ? "" : fingerprint,
    });
  };

  onSelectPaymentWalletOption = (selectedPaymentWalletOption) =>
    this.setState({ selectedPaymentWalletOption });

  onUpdateCustomerProfileAfterAdd = async ({ billingDetails = {} }) => {
    const updatedCustomerProfile = GetUpdatedCustomerProfile({
      customerProfile: this.props.context.customerProfile,
      newData: billingDetails,
    });
    const { success } = await ChangeCustomerProfile({
      customerProfile: updatedCustomerProfile,
      uuid: LocalStorage.getItemFromStorage("uuid"),
    });
    if (success) {
      const { onChangeCustomerProfile } = this.props.context;
      onChangeCustomerProfile &&
        onChangeCustomerProfile(updatedCustomerProfile);
    }
  };

  renderContent = () => {
    const {
      allowDelivery = true,
      allowDineIn = true,
      store_front = {},
      tipAmount,
      courierQuotes = {},
      selectedCourier,
    } = this.state;
    const { context, salesTax = 0 } = this.props;
    const { order_fulfillment_method = "pickUp" } = context;
    const {
      is_enable: is_storefront_enabled = false,
      guest_fees = {},
    } = store_front;
    const { fees_details = [], total_fee = 0 } = _calculateFees({
      fees: guest_fees,
      subTotal: parseFloat(this.props.subTotal),
      salesTax,
      tipAmount,
      totalDeliveryFee:
        order_fulfillment_method === "deliver"
          ? _getTotalDeliveryFee({ courierQuotes, selectedCourier })
          : 0,
    });

    return (
      <div
        ref={(ref) => {
          this.refs = set(this.refs, "expressCheckout", ref);
        }}
      >
        {allowDelivery && order_fulfillment_method !== "deliver" && (
          <ConfirmOrderModules.NewDeliveryFeature
            onChangeDeliveryType={this.onChangeDeliveryType}
          />
        )}
        <div className={Style.orderDeliveryTypeSelectors}>
          <ConfirmOrderModules.DeliveryTypeSelector
            allowDineIn={allowDineIn}
            onSelect={this.onChangeDeliveryType}
            selectedTypeID={order_fulfillment_method}
          />
        </div>
        <ConfirmOrderForm
          {...this.state}
          allowDelivery={allowDelivery}
          fees_details={fees_details}
          guest_fees={guest_fees}
          onAddPaymentMethod={() =>
            this.setState({ showAddPaymentMethodModal: true })
          }
          onChangeCourierQuotes={this.onChangeCourierQuotes}
          onChangeCurbsideInfo={(val) => this.setState({ curbsideInfo: val })}
          onChangeCustomerName={(val) => this.setState({ customerName: val })}
          onChangeDeliveryAddress={this.onChangeDeliveryAddress}
          onChangeDeliveryNotes={(val) => this.setState({ deliveryNotes: val })}
          onChangeDeliveryType={this.onChangeDeliveryType}
          onChangePhoneNumber={(val) => this.setState({ phoneNumber: val })}
          onChangePickupTime={(val) => this.setState({ pickUpTime: val })}
          onChangeTableNumber={(val) => this.setState({ tableNumber: val })}
          onChangeTipAmount={(tipAmount) => this.setState({ tipAmount })}
          orderDeliveryTypeID={order_fulfillment_method}
          onHideGettingQuoteLoader={() =>
            this.setState({ isGettingDeliveryQuote: false })
          }
          onSelectPaymentMethod={this.onSelectPaymentMethod}
          onSelectPaymentWalletOption={(selectedPaymentWalletOption) =>
            this.setState({ selectedPaymentWalletOption })
          }
          onShowGettingQuoteLoader={() =>
            this.setState({ isGettingDeliveryQuote: true })
          }
          onSubmitForm={this.onCreateCharge}
          on_update_dropoff_options={(dropoff_option) =>
            this.setState({ dropoff_option })
          }
          is_storefront_enabled={is_storefront_enabled}
          salesTax={this.props.salesTax}
          subTotal={this.props.subTotal}
          total_fee={total_fee}  // total_fee is store_front_total_fee
        />
      </div>
    );
  };

  render() {
    const { paymentMethods = {} } = this.state;
    return (
      // <CenterModal
      //   contentLabel="Confirm order modal"
      //   onCloseModal={this.props.onCloseModal}
      //   modalBoxClassname={Style.modalBoxConfirmOrder}
      //   contentContainerClassname={Style.modalContainer}
      //   closeModalButtonLabel={<ArrowIcon className={Style.closeModalIcon} />}
      //   showCloseButton
      // >
      //   {this.state.showAddPaymentMethodModal && (
      //     <Modals.AddNewPaymentMethodModal
      //       onCloseModal={this.onCloseAddPaymentMethodModal}
      //       onUpdateCustomerProfileAfterAdd={
      //         this.onUpdateCustomerProfileAfterAdd
      //       }
      //       pastPaymentMethodFingerprints={Object.keys(paymentMethods)}
      //     />
      //   )}
      //   {this.state.isCreatingCharge && (
      //     <Modals.LoadingModal message="Processing..." />
      //   )}
      //   {this.renderContent()}
      // </CenterModal>
      <React.Fragment>
        {this.state.showAddPaymentMethodModal && (
          <Modals.AddNewPaymentMethodModal
            onCloseModal={this.onCloseAddPaymentMethodModal}
            onUpdateCustomerProfileAfterAdd={
              this.onUpdateCustomerProfileAfterAdd
            }
            pastPaymentMethodFingerprints={Object.keys(paymentMethods)}
          />
        )}
        {this.state.isCreatingCharge && (
          <Modals.LoadingModal message="Processing..." />
        )}
        {this.renderContent()}
      </React.Fragment>
    );
  }
}

ConfirmOrder.propTypes = {
  context: PropTypes.shape({
    customerProfile: PropTypes.object.isRequired,
    onChangeCustomerProfile: PropTypes.func.isRequired,
    onChangeOrderFulfillmentMethod: PropTypes.func.isRequired,
    order_fulfillment_method: PropTypes.oneOf([
      "catering",
      "inStore",
      "pickUp",
      "deliver",
    ]).isRequired,
    showCateringMenu: PropTypes.bool,
    todayHours: PropTypes.shape({
      isShopClosed: PropTypes.bool,
      shopTodayOpenHours: PropTypes.object,
      today: PropTypes.oneOf([
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
        "sunday",
      ]),
    }).isRequired,
  }).isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onSubmitOrder: PropTypes.func.isRequired,
  salesTax: PropTypes.number.isRequired,
  stripe: PropTypes.object.isRequired,
  subTotal: PropTypes.number.isRequired,
};

export default withContext(CustomerInterfaceConsumer)(ConfirmOrder);
