// BUTI DINERS, INC. All right Reserved ©

import _orderBy from "lodash.orderby";
import _get from "lodash.get";

const _sortMenuItems = ({ items = {} }) =>
  _orderBy(
    Object.keys(items),
    [
      (item_id) => {
        const value = _get(items, `${item_id}.itemIsOutOfStock.true`);
        return value === "checked" ? 0 : 1;
      },
      (item_id) => _get(items, `${item_id}.itemName`) || "",
    ],
    ["desc", "asc"]
  );

const filteredCategories = ({ categories = {}, today = "", time = "" }) => {
  Object.keys(categories).forEach((key) => {
    const time_setting = categories[key].time_setting;
    if (!time_setting) {
      categories[key].is_available = true;
      return;
    }

    const {
      end_time,
      start_time,
      selected_date_options,
      time_selection,
    } = time_setting;

    let date_match = true;
    let time_match = true;

    if (selected_date_options) {
      if (Array.isArray(selected_date_options)) {
        date_match = selected_date_options.includes(today);
      } else if (typeof selected_date_options === "object") {
        const day_option = selected_date_options[today.toLowerCase()];
        if (day_option) {
          const {
            startAt: day_start,
            endAt: day_end,
            is_all_day = false,
            is_closed_all_day = false,
            extraHours = {},
          } = day_option;

          if (is_all_day) {
            time_match = true;
          } else if (is_closed_all_day) {
            time_match = false;
          } else if (!day_start && !day_end) {
            time_match = true;
          } else {
            time_match = time >= day_start && time <= day_end;
            if (!time_match && extraHours) {
              time_match = Object.values(extraHours).some(
                ({ startAt: extra_start, endAt: extra_end }) =>
                  time >= extra_start && time <= extra_end
              );
            }
          }
        } else {
          date_match = false;
        }
      }
    }

    if (time_selection === "custom_time") {
      time_match = time >= start_time && time <= end_time;
    }

    if (date_match && time_match) {
      categories[key].is_available = true;
    } else {
      categories[key].is_available = false;
    }
  });

  return categories;
};

export { _sortMenuItems, filteredCategories };
