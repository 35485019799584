// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

import { SHOPS_WITH_DEFAULT_ZERO_TIP } from "./constants";

// Style
import CommonStyle from "../style.module.scss";
import Style from "./style.module.scss";

// Fields
import { ExpansionPanel, QuantityCalculator, Tabs } from "fields";

// Lib
import { CUSTOMER_TIPPING_OPTIONS } from "lib/_constants";
import { Math } from "lib/functions";

const MIN_SUBTOTAL_FOR_DELIVERY_REQUIRED_TIP = 100;

class TipAmountSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tipOptions: this.onCreateTipOptions({
        orderDeliveryTypeID: props.orderDeliveryTypeID,
        subTotal: props.subTotal,
      }),
    };
  }

  componentDidMount = () =>
    this.onSelectTippingOption(
      SHOPS_WITH_DEFAULT_ZERO_TIP.includes(this.props.shop_id)
        ? "other"
        : "option4"
    );

  onCalculateDefaultCustomTip = () => {
    const { shop_id = "" } = this.props;
    if (SHOPS_WITH_DEFAULT_ZERO_TIP.includes(shop_id)) return 0;
    const desiredAmount = parseFloat(this.props.subTotalWithTax) * 0.1;
    return parseFloat(Math._roundUp(desiredAmount < 2 ? 2 : desiredAmount));
  };

  onChangeCustomTipAmount = (increment) => {
    const { tipAmount = 0 } = this.props;
    const minTipAmount = 0;
    const newTipAmount = parseFloat(tipAmount) + increment;
    this.props.onChangeTipAmount(
      newTipAmount > minTipAmount ? newTipAmount : minTipAmount
    );
  };

  onCreateTipOptions = ({ orderDeliveryTypeID, subTotal }) =>
    CUSTOMER_TIPPING_OPTIONS({
      minSubtotalForDeliveryRequiredTip: MIN_SUBTOTAL_FOR_DELIVERY_REQUIRED_TIP,
      orderDeliveryTypeID,
      subTotal,
    });

  onGetTipAmount = (tipOptionID) => {
    switch (tipOptionID) {
      case "other":
        return this.onCalculateDefaultCustomTip();
      default:
        const { multiplier } = this.state.tipOptions[tipOptionID];
        return parseFloat(
          Math._roundNumber(this.props.subTotalWithTax * multiplier)
        );
    }
  };

  onSelectTippingOption = (selectedTipOptionID) =>
    this.setState({ selectedTipOptionID }, () =>
      this.props.onChangeTipAmount(this.onGetTipAmount(selectedTipOptionID))
    );

  renderCustomTipSelector = () => (
    <div className={Style.customTipContainer}>
      <div>Select a tip amount</div>
      <QuantityCalculator
        currentQuantity={`$${Math._roundNumber(
          parseFloat(this.props.tipAmount)
        )}`}
        incrementAmount={0.5}
        quantityNumberClassName={Style.customTipAmount}
        minusButtonClassName={Style.customTipButton}
        onButtonClick={this.onChangeCustomTipAmount}
        plusButtonClassName={Style.customTipButton}
      />
    </div>
  );

  renderDeliveryTipMessage = () => (
    <p className={Style.message}>
      The recommended tip is based on the delivery distance & effort. The tip
      goes to the driver.
    </p>
  );

  renderMessage = () =>
    this.props.orderDeliveryTypeID === "deliver" &&
    parseFloat(this.props.subTotal) >= 20 && (
      <p className={Style.message}>
        10% tip is required for delivery order above $20
      </p>
    );

  render() {
    return (
      <ExpansionPanel
        moduleTitle={this.props.title}
        showExpandableContent
        titleClassName={CommonStyle.expansionPanelTitle}
      >
        <Tabs
          classNames={{ selected: Style.selected }}
          onClick={this.onSelectTippingOption}
          options={this.state.tipOptions}
          selectedOptionId={this.state.selectedTipOptionID}
        />
        {/* {this.renderMessage()} */}
        {this.state.selectedTipOptionID === "other" &&
          this.renderCustomTipSelector()}
        {/* {orderDeliveryTypeID === "deliver" &&
          subTotal >= MIN_SUBTOTAL_FOR_DELIVERY_REQUIRED_TIP &&
          this.renderDeliveryTipMessage()} */}
      </ExpansionPanel>
    );
  }

  // onChangeTipInputValue = (value) => {
  //   let newTipAmount = parseFloat(value);
  //   if (isNaN(newTipAmount) || newTipAmount < 0) newTipAmount = 0;
  //   this.props.onChangeTipAmount(newTipAmount);
  // };
}

TipAmountSelector.propTypes = {
  onChangeTipAmount: PropTypes.func.isRequired,
  orderDeliveryTypeID: PropTypes.string.isRequired,
  shop_id: PropTypes.string.isRequired,
  subTotalWithTax: PropTypes.number.isRequired,
  subTotal: PropTypes.number.isRequired,
  tipAmount: PropTypes.any,
  title: PropTypes.string.isRequired,
};

export default TipAmountSelector;
