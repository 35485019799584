// BUTI DINERS, INC. All right Reserved ©

import dayjs from "dayjs";

const weekDaysOrder = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

const sortWeekDays = (days = "") => {
  return days.sort(
    (a, b) =>
      weekDaysOrder.indexOf(a.toLowerCase()) -
      weekDaysOrder.indexOf(b.toLowerCase())
  );
};

const formatTime = (time_string = "") =>
  dayjs("1/1/1 " + time_string).format("h:mm A");

export { formatTime, sortWeekDays };
