// BUTI DINERS, INC. All right Reserved ©

const _getModalContent = ({ fee_id }) => {
  switch (fee_id) {
    case "convenience":
      return "This service fee enables us to operate our system and help our partner restaurants reduce their operational costs, which allows them to keep menu prices affordable. It also ensures that we maintain a high-quality, user-friendly platform, while making sure it's always fast, secure, and reliable.";
    case "online_payment_processing":
      return "The online payment processing fee helps cover the costs of securely handling and processing your payment. This fee supports the technology and systems that ensure your transaction is safe, fast, and seamless.";
    case "sub_total":
      return "Subtotal is the total cost of all items before taxes and additional charges are applied.";
    case "delivery":
      return "Delivery fee varies for each restaurant based on your location and other factors.";
    default:
      return "";
  }
};

export { _getModalContent };
